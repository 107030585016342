import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import { MEASUREMENTS } from '../../helpers/constants'

export default function SubNavigation( props ) {

	const { navItem, toggleMenu, menuToggled, toggleSubNav, width } = props
	const [ active, setActive ] = useState( false )
	const [ firstClick, setFirstClick ] = useState( true )

	const isMobile = width < MEASUREMENTS.TABLET; // less than tablet

	useEffect(() => {
		setFirstClick( true )
		setActive( false )
		toggleSubNav( false )
	}, [ menuToggled, toggleSubNav ]) 

	const handleSubNavClick = ( e ) => {
		if ( !isMobile ) {
			return
		}

		if ( firstClick && isMobile ) {
			e.preventDefault()
			setFirstClick( false )
			toggleSubNav( true )
		} else {
			toggleMenu( false )
			setFirstClick( true )
		}
		setActive( !active )
	}

	return (
		<li className={`navigation__item navigation__item--has-dropdown ${active ? 'is-open': ''}`}>
			<div className="navigation__dropdown">
				<div className="navigation__link-container">
					<Link
						to={ navItem.url }
						className="navigation__link"
						activeClassName="active"
						onClick={ (e) => handleSubNavClick( e )}>
						{ navItem.label }
					</Link>
				</div>
				<ul className={`navigation__dropdown-options ${active ? 'is-open': ''}`}>
					{ navItem.subNavItems.map( ( subItem, index ) => (
						<li className="navigation__dropdown-option" key={ index}>
							<Link
								to={ subItem.url }
								className="navigation__link navigation__link--in-dropdown"
								activeClassName="active"
								onClick={() => toggleMenu( false )}>
								{ subItem.label }
							</Link>
						</li>
					) ) }
				</ul>
			</div>
		</li>
	)
}