import React from 'react'
import { Link } from 'gatsby'

import Flowers from '../../images/flowers.png'

export default function Footer() {

	return (
		<footer className="footer" style={{ backgroundImage: `url(${Flowers})` }}>
			<div className="footer__wrapper">
				<div className="footer__content-container">
					<div className="footer__info-title">alitus</div>
					<div className="footer__info">Personal coaching for you and your business</div>
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">Contact Us</div>
					<ul className="footer__list">
						<li className="footer__list-item"><a className="footer__link" href="tel:01379678483">01379 678483</a></li>
						<li className="footer__list-item"><a className="footer__link" href="mailto:info@alitus.co.uk">info@alitus.co.uk</a></li>
						<li className="footer__list-item"><Link className="footer__link" to="/contact-us">Contact us</Link></li>
					</ul>
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">Personal</div>
					<ul className="footer__list">
						<li className="footer__list-item"><Link className="footer__link" to="/training">Training</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/workshops">Workshops</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/coaching">Coaching</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/conversations">Conversations</Link></li>
					</ul>
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">Business</div>
					<ul className="footer__list">
						<li className="footer__list-item"><Link className="footer__link" to="/executive">Executive</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/profiling">Profiling</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/testimonials">Testimonials</Link></li>
					</ul>
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">The Technical Stuff</div>
					<ul className="footer__list">
						<li className="footer__list-item"><Link className="footer__link" to="/cookie-policy">Cookie Policy</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/privacy-policy">Privacy Policy</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/behind-the-scenes">Behind the scenes</Link></li>
					</ul>
				</div>
			</div>
		</footer>
	)
}
