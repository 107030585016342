import React, { useEffect, useRef } from "react";
import { Link } from "gatsby"

export default () => {

	const cookieRef = useRef()

	const handleSubmit = () => {
		localStorage.setItem( 'cookie-policy-chosen', true );
		cookieRef.current.classList.add( 'is-hidden' )
	}

	const checkCookieOnLoad = () => {
		const cookiePolicyChosen  = localStorage.getItem( 'cookie-policy-chosen' )

		if ( !cookiePolicyChosen || cookiePolicyChosen !== 'true' ) {
			cookieRef.current.classList.remove( 'is-hidden' )
		}
	};

	useEffect(() => {
		checkCookieOnLoad()
	}, [] )

	return (
		<section className={`cookie-notice is-hidden `} ref={ cookieRef }>
			<div className="wrapper">
				<div className="cookie-notice__body">
					<div className="cookie-notice__text">
						<h4 className="cookie-notice__title">Cookies on alitus</h4>
						<p className="cookie-notice__message">We use cookies to give you the best possible online experience. If you continue, we’ll assume you are happy for your web browser to receive all cookies from our website. See our <Link className="cookie-notice__link" to="/cookie-policy">cookie policy</Link> for more information on cookies and how to manage them.</p>
					</div>
					<button className="cookie-notice__button button button--no-margin button--primary"
						value={ true }
						onClick={ () => handleSubmit() }>
						Accept
					</button>
				</div>
			</div>
		</section>
	)
}