import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

import SubNavigation from './SubNavigation'

export default function Navigation( props ) {

	const { toggleMenu, menuToggled, toggleSubNav, width } = props

	const queryData = useStaticQuery(graphql`
		query CategoryNavQuery {
			strapi {
				navigation {
					navItems {
						id
						label
						url
						subNavItems {
							id
							label
							url
						}
					}
				}
			}
		}
	`)

	const data = queryData.strapi.navigation.navItems

	return (
		<div className={`navigation ${ menuToggled ? 'is-open' : '' }`}>
			<nav className="navigation__menu">
				<div className="navigation__wrapper">
					<ul className="navigation__list">
						{ data.map( navItem => (
							<React.Fragment key={ navItem.id }>
								{ navItem.subNavItems.length ? (
									<SubNavigation navItem={ navItem } toggleMenu={ toggleMenu } menuToggled={ menuToggled } toggleSubNav={ toggleSubNav } width={ width } />
								) : (
									<li className="navigation__item">
										<Link
											to={ navItem.url }
											className={`navigation__link ${ navItem.label === "Executive" ? 'navigation__link--executive' : '' }`}
											activeClassName="active"
											partiallyActive={ true }
											onClick={() => toggleMenu( false ) }>
											{ navItem.label }
										</Link>
									</li>
								) }
							</React.Fragment>
						) ) }
					</ul>
				</div>
			</nav>
		</div>
	)
}